import React from "react";
import imgPlatformStories from "../../../../assets/img/upgraded/instagram-stories.svg";
import imgPlatformFacebook from "../../../../assets/img/upgraded/fb.svg";
import imgPlatformInstagram from "../../../../assets/img/upgraded/ig.svg";
import {IconArrowNarrowRight, IconEye, IconMovie, IconPhoto, IconSquareCheckFilled, IconSquareRoundedCheckFilled, IconX} from "@tabler/icons-react";
import ProgressBarStepped from "../../../ui/ProgressBarStepped/ProgressBarStepped";
import {Link} from "react-router-dom";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";


export default function PublicationsListTabledPlatforms({publication}) {


    const onDelete = () => {
        console.log('delete');
    }


    return (
        <div className="publications-list-tabled-platforms">
            <div><img src={imgPlatformStories} alt="Stories icon" className="img-icon"/></div>
            <div>8:21</div>
            <div><IconMovie size={24} stroke={2} className="me-2" />&times;1</div>
            <div><IconSquareRoundedCheckFilled size={24} stroke={2} className="text-success"/></div>
            <div className="status-field">
                <ProgressBarStepped
                    total={4}
                    progress={3}
                />
                Запланована
            </div>
            <div>
                <div className="d-none d-lg-inline">
                    <Link to={'/'} className="btn btn-sm btn-light-primary text-primary">Переглянути</Link>
                </div>
                <div className="d-inline d-lg-none">
                    <Link to={'/'} className="btn btn-sm btn-icon btn-light-primary text-primary">
                        <IconArrowNarrowRight size={15} stroke={2}/>
                    </Link>
                </div>

                <ButtonWithLoading
                    className="btn btn-sm btn-icon btn-light-danger text-danger ms-2"
                    spinnerClassName="spinner-border spinner-border-sm"
                    iconClassName="d-none"
                    label={(<IconX size={11} stroke={4}/>)}
                    onClick={onDelete}
                    loading={false}
                    noOriginalClassName={true}
                    noOriginalSpinnerClassName={true}
                    noOriginalIconClassName={true}
                />
            </div>
        </div>
    );
}