import React, {useEffect} from 'react';
import SectionNav from "../layout/SectionNav";
import {
    IconArrowLeft,
    IconArrowNarrowLeft,
    IconArrowNarrowRight,
    IconArrowRight, IconBellRinging,
    IconExclamationCircle,
    IconInfoSquareRounded, IconPencil, IconPlus,
    IconShare
} from "@tabler/icons-react";
import LineChartPlan from "../ui/charts/LineChartPlan";
import ProgressBarStepped from "../ui/ProgressBarStepped/ProgressBarStepped";
import SimpleBar from "simplebar-react";
import imgProfileTmp from "modernize-essentials/dist/images/profile/user-1.jpg";
import {Link} from "react-router-dom";
import PublicationsListTabled from "../publications/common/PublicationsListTabled/PublicationsListTabled";
import ProjectTitleExtended from "../projects/common/ProjectTitleExtended/ProjectTitleExtended";
import HeaderNotifications from "../layout/ui/HeaderNotifications";
import PublicationPlanCard from "../publications/common/PublicationPlanCard";
import ProjectEfficiencyCard from "../projects/common/ProjectEfficiencyCard";
import ProjectsModel from "../../models/Projects/ProjectsModel";
import {useApi} from "../../providers/ApiProvider";
import {useMeta} from "../../providers/MetaProvider";


export default function Project({}) {
    const apiContext = useApi();
    const metaContext = useMeta();
    const projectsModel = new ProjectsModel(apiContext.api);

    const projectId = 'citroen.kh.ua';
    const [project, setProject] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        document.title = 'Likengale';

        getProject();
    }, []);


    useEffect(() => {
        if (project.id) {
            metaContext.update({
                title: project.title
            });
        }
    }, [project]);


    const getProject = () => {
        setLoading(true);

        projectsModel.view(projectId).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const visibilityDummyData = {
        current: [{x: 0, y: 0}, {x: 5, y: 0.3}, {x: 10, y: 0.7}, {x: 15, y: 1.35}, {x: 20, y: 1.9}, {x: 25, y: 2.7}, {x: 30, y: 2.9}, {x: 35, y: 4.1}, {
            x: 40,
            y: 4.9
        }, {x: 45, y: 6.4}, {x: 50, y: 7.5}, {x: 55, y: 9.1}, {x: 60, y: 9.5}],
        average: [{x: 0, y: 0}, {x: 5, y: 0.7}, {x: 10, y: 1.6}, {x: 15, y: 3.1}, {x: 20, y: 4.2}, {x: 25, y: 4.9}, {x: 30, y: 5.8}, {x: 35, y: 6}, {
            x: 40,
            y: 8.5
        }, {x: 45, y: 8.9}, {x: 50, y: 9.9}, {x: 55, y: 10.5}, {x: 60, y: 11.2}],
        best: [{x: 0, y: 0}, {x: 5, y: 1}, {x: 10, y: 2}, {x: 15, y: 4}, {x: 20, y: 5}, {x: 25, y: 7}, {x: 30, y: 9}, {x: 35, y: 10}, {x: 40, y: 11}, {
            x: 45,
            y: 11.5
        }, {x: 50, y: 12.9}, {x: 55, y: 13.5}, {x: 60, y: 17.9}],
        expected: [{x: 0, y: 0}, {x: 60, y: 8}],
    };

    const efficiencyDummyData = {
        current: [{x: 0, y: 0}, {x: 5, y: 0.3}, {x: 10, y: 0.7}, {x: 15, y: 1.35}, {x: 20, y: 1.9}, {x: 25, y: 2.7}, {x: 30, y: 2.9}, {x: 35, y: 4.1}, {
            x: 40,
            y: 4.9
        }, {x: 45, y: 6.4}, {x: 50, y: 7.5}, {x: 55, y: 9.1}, {x: 60, y: 9.5}],
        average: [{x: 0, y: 0}, {x: 5, y: 0.7}, {x: 10, y: 1.6}, {x: 15, y: 3.1}, {x: 20, y: 4.2}, {x: 25, y: 4.9}, {x: 30, y: 5.8}, {x: 35, y: 6}, {
            x: 40,
            y: 8.5
        }, {x: 45, y: 8.9}, {x: 50, y: 9.9}, {x: 55, y: 10.5}, {x: 60, y: 11.2}],
        best: [{x: 0, y: 0}, {x: 5, y: 1}, {x: 10, y: 2}, {x: 15, y: 4}, {x: 20, y: 5.2}, {x: 25, y: 7}, {x: 30, y: 9}, {x: 35, y: 10}, {x: 40, y: 11}, {
            x: 45,
            y: 11.5
        }, {x: 50, y: 12.9}, {x: 55, y: 13.5}, {x: 60, y: 17.9}],
        expected: [{x: 0, y: 0}, {x: 60, y: 8}],
    }


    return (
        <>
            <ProjectTitleExtended/>

            <div className="d-flex flex-column block-gap mt-st">
                <div className="row block-gutter">
                    <div className="col-xl-8">
                        <SimpleBar autoHide={true}>
                            <div className="d-flex mb-st">
                                <button className="btn btn-sm btn-light-primary text-primary">Охоплення</button>
                                <button className="btn btn-sm text-primary">Підписники</button>
                                <button className="btn btn-sm text-primary">Видимість</button>
                                <button className="btn btn-sm text-primary">Залученість</button>
                                <button className="btn btn-sm text-primary">Витрати</button>
                            </div>
                        </SimpleBar>

                        <div className="line-chart-noted full-width active">
                            <div className="line-chart-noted-title">
                                <h3>
                                    <div className="title-text">
                                        Органічне охоплення
                                        <a href="#"><IconInfoSquareRounded size={20} stroke={2}/></a>
                                    </div>
                                </h3>

                                <p className="chart-description">Загальна кількість унікальних охоплених акаунтів для профілів та публікацій, здійснена
                                    органічними засобами.</p>
                            </div>

                            <LineChartPlan
                                data={visibilityDummyData}
                            />
                        </div>


                        <nav className="section-nav mb-0">
                            <SimpleBar autoHide={true} className="top-level">
                                <div className="top-level-content">
                                    <Link to="/plan/pubs">Плани публікацій</Link>
                                    <Link to="/">Завдання на місяць</Link>
                                    <Link to="/">Конкуренти за планом</Link>
                                </div>
                            </SimpleBar>
                        </nav>


                        <h3 className="lh-1 m-0 mb-st mt-4">План від 14 жовтня</h3>

                        <PublicationPlanCard />

                        <ProjectEfficiencyCard
                            project={project}
                            loading={loading}
                            cardClassName="m-block-gap"
                        />
                    </div>
                    <div className="col-xl-4">
                    <h3 className="lh-1 m-0">Конкуренти</h3>
                    </div>
                </div>
            </div>
        </>
    );
}