import React, {useState, useCallback} from "react";

import SimpleBar from "simplebar-react";
import {useAuth} from "../../../providers/AuthProvider";

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';
import {Link} from "react-router-dom";
import {IconSettings} from "@tabler/icons-react";


function HeaderProfile({}) {
    const authContext = useAuth();
    const userData = authContext.user;

    const [hasNotifications, setHasNotifications] = useState(true);

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    const getLabelByRole = (type) => {
        let label = 'Без ролі';
        switch (type) {
            case 'admin': label = 'Адміністратор'; break;
        }

        return label;
    }


    return (<>
        <div id="header-profile-label" data-bs-toggle="dropdown" aria-expanded="false">
            <div className={'header-profile-image' + (hasNotifications ? ' active' : '')}>
                <img src={imgProfile} alt="Profile image" />
            </div>

            <div className="header-profile-name d-none d-lg-flex">
                <div className="header-profile-title">
                    <h6>Пежо на Центральному</h6>

                    <IconSettings size={17} stroke={3}/>
                </div>

                <span>@peugeot.kh.ua</span>
            </div>
        </div>

        <div className="dropdown-menu dropdown-menu-end mt-3" style={{width: '300px'}} aria-labelledby="header-profile-label">
            <SimpleBar autoHide={true} className={'profile-dropdown position-relative'}>
                <div className="py-3 px-7 pb-0">
                    <h5 className="mb-0 fs-5 fw-semibold">Ваш профіль</h5>
                </div>
                <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                    <img src={imgProfile} className="rounded-circle" width="80" height="80" alt=""/>
                    <div className="ms-3">
                        <h5 className="fs-4 mb-2">{userData.name}</h5>
                        <span className="mb-0 d-block text-dark">{getLabelByRole(userData.type)}</span>
                        <p className="mb-0 d-flex text-dark align-items-center">
                            {userData.email}
                        </p>
                    </div>
                </div>
                <div className="message-body">
                    <Link to={'/projects'} reloadDocument className="py-8 px-7 mt-8 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-building-store fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-primary fw-semibold"> Проєкти</h6>
                        </div>
                    </Link>
                    <Link to={'/brands'} reloadDocument className="py-8 px-7 mt-8 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-brand-apple fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-primary fw-semibold"> Бренди</h6>
                            <span className="d-block text-dark fs-2">та теми до них</span>
                        </div>
                    </Link>
                    <Link to={'/checklists'} reloadDocument className="py-8 px-7 mt-8 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-checklist fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                        <h6 className="mb-0 bg-hover-primary fw-semibold"> Чек-лісти</h6>
                            <span className="d-block text-dark fs-2">до контент-планів</span>
                        </div>
                    </Link>
                    <Link to={'/settings/connected-accounts'} reloadDocument className="py-8 px-7 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-users fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-primary fw-semibold"> Користувачі</h6>
                        </div>
                    </Link>
                    <Link to={'/settings/connected-accounts'} reloadDocument className="py-8 px-7 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <i className={'ti ti-settings fs-6'}></i>
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-0 bg-hover-primary fw-semibold"> Налаштування</h6>
                        </div>
                    </Link>
                </div>
                <div className="d-grid py-4 px-7 pt-8">
                    <a href="/auth/logout" className="btn btn-outline-primary">Завершити сеанс</a>
                </div>
            </SimpleBar>
        </div>
    </>)
}

export default HeaderProfile;