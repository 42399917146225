import React from 'react';
import {IconShare, IconPencil} from '@tabler/icons-react';
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import HeaderNotifications from "./ui/HeaderNotifications";


export default function SectionNav({}) {


    const urlStartsWith = (url) => {
        return window.location.pathname.startsWith(url);
    }


    return (
        <nav className="section-nav">
            <SimpleBar autoHide={true} className="top-level">
                <div className="top-level-content">
                    <Link to="/plan/pubs">Плани публікацій</Link>
                    <Link to="/">Завдання на місяць</Link>
                    <Link to="/">Конкуренти за планом</Link>
                </div>
            </SimpleBar>

            <div className="section-description">
                <h2>
                    <div className="section-text">План на тиждень</div>

                    <div className="section-toolbar">
                        <HeaderNotifications />

                        <Link to="/plan/pubs">
                            <IconShare size={20} stroke={2} />
                        </Link>

                        <Link to="/plan/weekly">
                            <IconPencil size={20} stroke={2} />
                        </Link>
                    </div>
                </h2>
                <div className="section-subtitle">період 8-15 липня</div>
            </div>
        </nav>
    );
}