import React, {useEffect} from 'react';
import FormTextArea from "../../layout/ui/FormTextArea";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import PublicationEditCaptionGenerateForm from "./PublicationEditCaptionGenerateForm";


export default function PublicationEditCaption({publication, onSubmit, captionEditMode, onEditModeChange, suggestions = []}) {
    const [editMode, setEditMode] = React.useState(false);

    const [caption, setCaption] = React.useState(publication && publication.latestVersion && publication.latestVersion.text ? publication.latestVersion.text : '');

    useEffect(() => {
        onEditModeChange(editMode);
    }, [editMode]);

    useEffect(() => {
        refreshCaption();
    }, [publication]);

    useEffect(() => {
        setEditMode(captionEditMode);
    }, [captionEditMode]);


    const refreshCaption = () => {
        if (publication && publication.latestVersion) {
            setCaption(publication.latestVersion.text ? publication.latestVersion.text : '');
        } else {
            setCaption('');
        }
    }


    const submitForm = () => {
        onSubmit(caption);
    }


    const resetForm = () => {
        refreshCaption();
        setEditMode(false);
    }


    return editMode ? (<>
        <h4 className="fs-5 fw-semibold mt-0 mb-3">
            Редагувати підпис до публікації
        </h4>
        <div className="row">
            <div className="col-lg-7">
                <FormTextArea
                    label={false}
                    name="caption"
                    value={caption}
                    onChange={(value) => setCaption(value)}
                    rows={24}
                    isCodeMirror={false}
                    groupClassName={'mb-3'}
                />

                <div className="d-flex gap-3">
                    <ButtonWithLoading
                        label={'Зберегти'}
                        className={'btn-primary'}
                        onClick={submitForm}
                        iconClassName={"d-none"}
                        loading={false}
                    />
                    <button className="btn btn-outline-danger" onClick={resetForm}>Скасувати</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="bordered-tip">
                    <div className="mb-5">
                        <h4 className="fs-3 text-uppercase text-muted fw-semibold d-flex align-items-center"><i className="ti ti-info-circle fs-4 me-2 d-none"></i>Побажання до тексту</h4>

                        {suggestions.length > 0 && (
                        <div className="d-flex flex-column gap-2">
                        {suggestions.map((s, i) => (
                            <div key={i} className="d-flex gap-2 align-items-start fs-4">
                                <i className="ti ti-checks fs-4 mt-1"></i>
                                <span className="fs-3">{s}</span>
                            </div>
                        ))}
                        </div>
                        )}
                    </div>

                    <PublicationEditCaptionGenerateForm
                        publication={publication}
                        onTextGenerated={(text) => console.log(text)}
                    />
                </div>
            </div>
        </div>
    </>) : (<>
        <h4 className="fs-5 fw-semibold mt-0 mb-3 cursor-pointer" onClick={() => setEditMode(true)}>
            Версії підпису до публікації
            <span className="py-1 ms-3 d-inline-flex align-items-center text-decoration-none" title="Редагувати">
                <i className="ti ti-pencil fs-3 text-dark"></i>
            </span>
        </h4>
        <div className="">{caption.split('\n').map((str, index, array) =>
            ((index === array.length - 1) || (!str.length)) ? str : <p key={index} className="mb-1">{str}</p>
        )}</div>
    </>);
}