import React, {useState} from 'react';

import './ProjectTitleExtended.css';

import {IconBellRinging, IconChartLine, IconPencil, IconShare} from "@tabler/icons-react";
import {useAuth} from "../../../../providers/AuthProvider";
import imgProfileTmp from "modernize-essentials/dist/images/profile/user-1.jpg";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";

export default function ProjectTitleExtended({loading, project}) {
    const authContext = useAuth();
    const userData = authContext.user;

    const [hasNotifications, setHasNotifications] = useState(true);

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    return (
        <div className="project-title-extended">
            <div className={'project-title-image' + (hasNotifications ? ' active' : '')}>
                <img src={imgProfile} alt="Profile image"/>
            </div>

            <div className="project-title-info">
                <div className="project-title-name">
                    <div className="project-title-heading">
                        <h6>Пежо на Центральному</h6>

                        <div className="project-title-actions d-none d-lg-flex">
                            <IconBellRinging size={20} stroke={2}/>

                            <IconChartLine size={20} stroke={2}/>

                            <IconShare size={20} stroke={2}/>

                            <IconPencil size={20} stroke={2}/>
                        </div>
                    </div>

                    <span>@peugeot.kh.ua</span>
                </div>

                <div className="w-100">
                    <SimpleBar autoHide={true}>
                        <div className="project-title-links">
                            <Link to={''}>Instagram</Link>
                            <Link to={''}>Facebook</Link>
                            <Link to={''}>Веб-сайт</Link>
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
}
