import React from "react";
import imgPlatformStories from "../../../../assets/img/upgraded/instagram-stories.svg";
import imgPlatformFacebook from "../../../../assets/img/upgraded/fb.svg";
import imgPlatformInstagram from "../../../../assets/img/upgraded/ig.svg";
import {IconMovie, IconPhoto, IconSquareCheckFilled, IconSquareRoundedCheckFilled, IconX} from "@tabler/icons-react";
import ProgressBarStepped from "../../../ui/ProgressBarStepped/ProgressBarStepped";
import {Link} from "react-router-dom";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import BadgePriority from "../../../ui/badges/BadgePriority";
import BadgePublicationEntertainment from "../../../ui/badges/BadgePublicationEntertainment";
import BadgePromoted from "../../../ui/badges/BadgePromoted";
import PublicationsListTabledPlatforms from "./PublicationsListTabledPlatforms";


export default function PublicationsListTabledItem({publication}) {


    const onDelete = () => {
        console.log('delete');
    }


    return (
        <>
            <div className="custom-responsive-behavior">
                <div>
                    <div className="publications-list-tabled-publication">
                        <div className="pub-date">понеділок, 8 липня</div>
                        <h2>Фінансування, страхування, трейд-ін</h2>
                    </div>
                </div>

                <div className="d-none d-lg-flex"></div>
            </div>
            <div className="custom-responsive-behavior">
                <div>
                    <div className="publications-list-tabled-publication">
                        <div className="pub-characteristics">
                            <BadgePriority/>
                            <BadgePublicationEntertainment/>
                            <BadgePromoted/>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="publications-list-tabled-platforms d-flex d-lg-none">
                        <div><h5>&nbsp;</h5></div>
                        <div><h5>Час</h5></div>
                        <div><h5>Медіа</h5></div>
                        <div><h5>Текст</h5></div>
                        <div className="status-field"><h5>Статус</h5></div>
                        <div><h5>&nbsp;</h5></div>
                    </div>

                    <PublicationsListTabledPlatforms/>
                    <PublicationsListTabledPlatforms/>
                    <PublicationsListTabledPlatforms/>
                </div>
            </div>
        </>
    );
}