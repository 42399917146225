import React from "react";


export default function BadgePublicationEntertainment({children, className = '', ...props}) {

    return (
        <div className={'badge badge-publication-entertainment ' + className} {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                <path
                    d="M12.8227 0.118685C12.71 0.0233513 12.5627 -0.0173153 12.4173 0.00668469L4.41733 1.34002C4.17667 1.38068 4 1.58935 4 1.83335V8.51268C3.58067 8.19535 3.06467 8.00002 2.5 8.00002C1.12133 8.00002 0 9.12202 0 10.5C0 11.878 1.12133 13 2.5 13C3.87867 13 5 11.878 5 10.5V5.59068L12 4.42402V7.17935C11.5807 6.86202 11.0647 6.66668 10.5 6.66668C9.12133 6.66668 8 7.78868 8 9.16668C8 10.5447 9.12133 11.6667 10.5 11.6667C11.8787 11.6667 13 10.5447 13 9.16668V0.500018C13 0.352685 12.9347 0.213351 12.8227 0.118685ZM2.49933 12C1.672 12 0.999333 11.3267 0.999333 10.5C0.999333 9.67335 1.672 9.00002 2.49933 9.00002C3.32667 9.00002 3.99933 9.67335 3.99933 10.5C3.99933 11.3267 3.32667 12 2.49933 12ZM10.4993 10.6667C9.672 10.6667 8.99933 9.99335 8.99933 9.16668C8.99933 8.34002 9.672 7.66668 10.4993 7.66668C11.3267 7.66668 11.9993 8.34002 11.9993 9.16668C11.9993 9.99335 11.3267 10.6667 10.4993 10.6667ZM4.99933 4.57602V2.25735L11.9993 1.09068V3.40935L4.99933 4.57602Z"
                    fill="#E30053"/>
            </svg>
            Розважальна
        </div>
    );
}