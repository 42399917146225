import React from "react";


export default function BadgePromoted({children, className = '', ...props}) {

    return (
        <div className={'badge badge-promoted ' + className} {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none">
                <path
                    d="M0.482715 8.36174C0.349552 8.36174 0.216389 8.29516 0.149808 8.22858C-0.049936 8.02883 -0.049936 7.69592 0.149808 7.49618L4.14469 3.5013C4.34443 3.30156 4.67734 3.30156 4.87708 3.5013L7.20743 5.83165L11.3355 1.70361H8.47247C8.20615 1.70361 7.93982 1.50386 7.93982 1.17096C7.93982 0.83805 8.13957 0.638306 8.47247 0.638306H12.4674C12.5339 0.638306 12.6005 0.638306 12.6671 0.704887C12.7337 0.704887 12.8003 0.771468 12.8003 0.83805C12.8003 0.904631 12.8668 0.971212 12.9334 0.971212C12.9334 1.03779 13 1.10437 13 1.17096V5.16583C13 5.43216 12.8003 5.69849 12.4674 5.69849C12.1344 5.69849 11.9347 5.49874 11.9347 5.16583V2.36942L7.47375 6.83037C7.27401 7.03011 6.9411 7.03011 6.74136 6.83037L4.47759 4.5666L0.815621 8.22858C0.74904 8.29516 0.615877 8.36174 0.482715 8.36174Z"
                    fill="#5BC200"/>
            </svg>
            На просуванні
        </div>
    );
}