import React from "react";

import './PublicationsListTabled.css';
import PublicationsListTabledItem from "./PublicationsListTabledItem";
import {Link} from "react-router-dom";
import {IconPlus} from "@tabler/icons-react";


export default function PublicationsListTabled({publications}) {

    return (
        <div className="">
            <div className="d-flex gap-7 flex-row align-items-center">
                <h3 className="lh-1 m-0">Публікації</h3>

                <Link to="/p/create" className="btn btn-success btn-round text-nowrap">
                    <IconPlus size={20} stroke={2.5} className="me-2"/>
                    Додати публікацію
                </Link>
            </div>

            <div className="publications-list-tabled">
                <div className="custom-responsive-behavior d-none d-lg-flex">
                    <div className="d-none d-lg-flex"></div>
                    <div>
                        <div className="publications-list-tabled-platforms">
                            <div><h5>&nbsp;</h5></div>
                            <div><h5>Час</h5></div>
                            <div><h5>Медіа</h5></div>
                            <div><h5>Текст</h5></div>
                            <div className="status-field"><h5>Статус</h5></div>
                            <div><h5>&nbsp;</h5></div>
                        </div>
                    </div>
                </div>

                <PublicationsListTabledItem/>
                <PublicationsListTabledItem/>
                <PublicationsListTabledItem/>
                <PublicationsListTabledItem/>
            </div>
        </div>
    );
}