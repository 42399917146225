import React, {useEffect} from 'react';
import Chart from "react-apexcharts";


export default function LineChartPlan({data, width = '100%', height = '320px', className = '', shouldBeGraded = false}) {
    const [chartOptions, setChartOptions] = React.useState({
        chart: {
            id: "pq-insights-chart-" + Math.round((Math.random() * 1000000)),
            type: "area",
            fontFamily: '"Inter", Arial, sans-serif',
            foreColor: "#adb0bb",
            toolbar: {
                show: false,
            },
        },
        fill: {
            type: ['gradient', 'solid', 'solid', 'solid'],
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0,
                opacityFrom: 0.4,
                opacityTo: 0,
                stops: [0, 100]
            }
        },
        stroke: {
            curve: ['monotoneCubic', 'monotoneCubic','monotoneCubic', 'straight'],
            width: [2, 1, 1, 1],
        },
        markers: {
            show: false,
            size: 0,
        },
        grid: {
            show: false
        },
        dataLabels: {
            show: false,
            enabled: false,
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            fontSize: '9px',
            fontWeight: 700,
            formatter: function (seriesName, opts) {
                return seriesName.toUpperCase()
            },
            offsetX: -2,
            offsetY: 10,
            height: 20,
            markers: {
                shape: 'circle',
                size: 4.5,
                strokeWidth: 0,
                offsetX: -3
            },
            itemMargin: {
                horizontal: 10, // Space between legend items
                vertical: 0
            },
            labels: {
                colors: '#333', // Color for legend text
                useSeriesColors: true
            }
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 3,
            position: 'bottom',
            decimalsInFloat: 0,
            labels: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#cacaca',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#cacaca',
                height: 6,
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            show: true,
            tickAmount: 4,
            showAlways: true,
            decimalsInFloat: 0,
            labels: {
                show: true,
                minWidth: 0,
                maxWidth: 16,
            },
            axisBorder: {
                show: true,
                color: '#cacaca',
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#cacaca',
                width: 6,
                offsetX: 0,
                offsetY: 0
            },
        },
        tooltip: {
            theme: "dark",
            x: {
                show: true,

            },
        },
    });
    const [dataSeries, setDataSeries] = React.useState({
        current: {
            name: 'Поточна',
            color: "#3771C8",
            type: 'area',
            zIndex: -40,
            data: []
        },
        average: {
            name: 'Середня',
            color: "#6D2AF6",
            type: 'line',
            zIndex: 30,
            data: []
        },
        best: {
            name: 'Найкраща',
            color: "#FB417F",
            type: 'line',
            zIndex: 20,
            data: []
        },
        expected: {
            name: 'Очікувана',
            color: "#0FBA00",
            type: 'line',
            zIndex: 10,
            data: []
        }
    });
    const [dataSeriesAsArray, setDataSeriesAsArray] = React.useState([]);


    useEffect(() => {
        if (!data) return;

        let dataSeriesNew = {...dataSeries};

        Object.keys(data).forEach((key, index) => {
            dataSeriesNew[key].data = data[key];
        });

        /*if (shouldBeGraded && data.series.length) {
            areaOptionsNew.colors = gradeColorsBySeries(data.series);
        }*/

        setDataSeries(dataSeriesNew);
        setDataSeriesAsArray(Object.values(dataSeriesNew));
    }, [data]);


    return dataSeriesAsArray.length && (
        <Chart type="area" options={chartOptions} series={dataSeriesAsArray} width={width} height={height} className={className}/>
    );
}