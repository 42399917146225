import React, {useEffect} from "react";

import logoInstagram from '../../../assets/img/instagram.svg';
import PublicationCardProgress from "../common/PublicationCardProgress";
import {Link, useParams} from "react-router-dom";
import PublicationEditForm from "../edit/PublicationEditForm";
import PublicationPreview from "../edit/PublicationPreview";
import PublicationEditMedia from "../edit/PublicationEditMedia";
import PublicationEditCaption from "../edit/PublicationEditCaption";
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import ContentPlansModel from "../../../models/Publications/PublicationPlansModel";
import PublicationsModel from "../../../models/Publications/PublicationsModel";
import PublicationVersionsModel from "../../../models/Publications/PublicationVersionsModel";


export default function PublicationView() {
    const {projectId, contentPlanId, publicationId} = useParams();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);
    const contentPlansModel = new ContentPlansModel(apiContext.api);
    const publicationsModel = new PublicationsModel(apiContext.api);
    const publicationVersionsModel = new PublicationVersionsModel(apiContext.api, publicationId);

    const [captionEditMode, setCaptionEditMode] = React.useState(false);
    const [captionSuggestions, setCaptionSuggestions] = React.useState([]);
    const [publication, setPublication] = React.useState(null);


    useEffect(() => {
        document.title = 'Публікація від 7 лютого | Likengale';

        updatePublication();

        const captionSugg = [
            'Обов\'язково вказати ціну на авто в наявності чи ціну «від ...» для моделі загалом',
            'Перелічити все платне додаткове обладнання',
            'Згадати про можливості кредитування чи лізингу',
            'Згадати про тест-драйв, якщо модель доступна для тестування',
        ];
        setCaptionSuggestions(captionSugg);
    }, []);


    const updatePublication = () => {
        publicationsModel.view(publicationId).then((response) => {
            if (response !== false) {
                setPublication(response);
            }
        });
    }


    const onMediaChanged = () => {
        updatePublication();
    }


    const onCaptionSave = (caption) => {
        let textUpdated;

        if (!publication.latestVersion) {
            textUpdated = publicationVersionsModel.create({text: caption});
        } else {
            textUpdated = publicationVersionsModel.update(publication.latestVersion.id, {text: caption});
        }

        textUpdated.then((response) => {
            if (response !== false) {
                notificationsContext.notify('Підпис до публікації успішно збережено.', 'success');
                updatePublication();
                setCaptionEditMode(false);
            }
        });
    }


    return publication ? (<>
        <div className="row">
            <div className="col-lg-3">
                <div className="d-flex w-100 justify-content-between mb-3">
                    <Link to={'/' + ['p', projectId, contentPlanId].join('/')}
                          className="btn btn-sm btn-light-primary text-primary d-inline-flex justify-content-center align-items-center gap-2">
                        <i className="ti ti-arrow-narrow-left fs-4"></i> Назад до плану
                    </Link>

                    <a href={'/' + ['p', projectId, contentPlanId, publicationId].join('/') + '/insights'}
                       className="bg-light rounded py-1 px-8 d-inline-flex align-items-center text-decoration-none"
                       title="Перейти до статистики">
                        <i className="ti ti-chart-line fs-3 text-dark"></i>
                    </a>
                </div>

                <div className="d-flex flex-column align-items-center m-block-gap">
                    <div className="position-relative">
                        <img src={logoInstagram} className="shadow-warning rounded-circle" alt="" width="72" height="72"/>
                        <div className="d-flex justify-content-center align-items-center rounded-circle bg-warning p-1 position-absolute"
                             style={{bottom: '-1px', right: '-1px'}}><i className="fs-2 text-white ti ti-star"></i></div>

                    </div>

                    <h5 className="fw-semibold fs-5 mt-2 mb-1">
                        Сервіс / Citroen Assistance <i className="fs-2 ms-2 text-danger ti ti-music position-absolute"></i>
                    </h5>
                    <p className="fs-3 mb-0">четвер, 21 березня, 15:30</p>

                    <div className="bg-muted text-light rounded fs-2 px-3 py-1 mt-3">Нова публікація</div>
                </div>

                <div className="w-100 m-block-gap">
                    <PublicationCardProgress progress={48} label={'Необхідно внести зміни до публікації.'}/>
                </div>

                <PublicationEditForm publication={publication} />

                <div className="card w-100">
                    <div className="card-body p-3">
                        <div className="mb-4">
                            <h5 className="card-title fw-semibold mb-2">Активність для публікації</h5>
                            <p className="card-subtitle text-muted fs-2">Востаннє оновлено о 15:57.</p>
                        </div>
                        <ul className="timeline-widget mb-0 position-relative mb-n5">
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">09:30</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1">Payment received from John Doe of $385.90</div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">10:00 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-info flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New sale recorded <a href="#"
                                                                                                                     className="text-primary d-block fw-normal ">#ML-3467</a>
                                </div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">12:00 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1">Payment was made of $64.95 to Michael</div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">09:30 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-warning flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New sale recorded <a href="#"
                                                                                                                     className="text-primary d-block fw-normal ">#ML-3467</a>
                                </div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">09:30 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-danger flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New arrival recorded <a href="#"
                                                                                                                        className="text-primary d-block fw-normal ">#ML-3467</a>
                                </div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">12:00 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1">Payment Done</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="d-flex flex-column gap-1 m-block-gap d-none">
                    <div className="alert alert-danger show d-flex align-items-center gap-2" role="alert">
                        <i className="ti ti-photo-edit fs-6 me-2"></i>
                        Є зауваження, які очікують бути внесеними. <a href="#" className="alert-link fw-semibold text-nowrap">Переглянути »</a>
                    </div>
                </div>

                <PublicationEditMedia
                    publication={publication}
                    media={publication.media ? publication.media : []}
                    captionEditMode={captionEditMode}
                    onMediaChanged={onMediaChanged}
                />

                <PublicationEditCaption
                    publication={publication}
                    suggestions={captionSuggestions}
                    captionEditMode={captionEditMode}
                    onEditModeChange={(editMode) => {setCaptionEditMode(editMode)}}
                    onSubmit={onCaptionSave}
                />
            </div>
            <div className="col-lg-4">
                <PublicationPreview/>
            </div>
        </div>
    </>) : '';
}