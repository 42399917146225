import React, {useEffect, useState} from 'react';

import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop'
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import PublicationMediaModel from "../../../models/Publications/PublicationMediaModel";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import SimpleModal from "../../ui/SimpleModal";

import 'react-image-crop/dist/ReactCrop.css';

export default function PublicationMediaCrop({publication, media, onMediaChanged, onModalClose}) {
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const publicationMediaModel = new PublicationMediaModel(apiContext.api, publication.id);

    const [crop, setCrop] = useState({
        unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50
    });
    const [completeCrop, setCompleteCrop] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [aspectRatio, setAspectRatio] = React.useState(null);

    useEffect(() => {
        let el = document.querySelector('.modal-media-crop img');
        if (!el) {return;}

        let width = el.naturalWidth,
            height = el.naturalHeight;

        let newCrop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                aspectRatio,
                width,
                height
            ),
            width,
            height
        );

        setCrop(newCrop);
    }, [aspectRatio]);


    const aspectRatioToNumber = (ratio) => {
        let value = null;
        if (ratio.indexOf(':') !== -1) {
            let parts = ratio.split(':');
            value = parseInt(parts[0]) / parseInt(parts[1]);
        }

        return value;
    }


    const updateAspectRatio = (ratio) => {
        const newRatio = aspectRatioToNumber(ratio);
        setAspectRatio(newRatio);
    }


    const onComplete = (crop, percentCrop) => {
        setCompleteCrop(percentCrop);
    }


    const onSave = () => {
        if (!media) {return;}

        let workingCrop = completeCrop;
        if (!workingCrop) {
            workingCrop = crop;
        }

        setLoading(true);

        let cropData = {
            x: workingCrop.x,
            y: workingCrop.y,
            width: workingCrop.width,
            height: workingCrop.height,
        };

        publicationMediaModel.crop(media.id, cropData).then((response) => {
            if (response !== false) {
                onMediaChanged();
                onModalClose();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const prepareModalClose = () => {
        setCrop({
            unit: '%',
            x: 25,
            y: 25,
            width: 50,
            height: 50
        });
        setCompleteCrop(null);
        setAspectRatio(null);

        onModalClose();
    }


    return !!media ? (<SimpleModal
                visible={!!media}
                loading={false}
                onClose={onModalClose}
                modalContentClassName={' '}
                headerTitle={'Редагування зображення'}
            >
                <ReactCrop className="modal-media-crop" crop={crop} aspect={aspectRatio} onChange={c => setCrop(c)} onComplete={onComplete} ruleOfThirds={true} style={{maxHeight: '60vh'}}>
                    <img src={media.filename} alt="" />
                </ReactCrop>

                <div className="my-2 fs-3 fw-semibold">Пропорція зображення</div>
                <div className="btn-group">
                    {['4:5', '5:4', '1:1', '3:2', '2:3', '2:1', '1:2', '16:9', '9:16', 'Вільно'].map((ratio, index) => (
                    <button key={index} type="button" className={'btn btn-sm font-medium '+((aspectRatioToNumber(ratio) === aspectRatio) ? 'btn-primary text-white' : 'btn-light-primary text-primary')} onClick={() => {updateAspectRatio(ratio)}}>
                        {ratio}
                    </button>
                    ))}
                </div>

                <div className="form-group mt-5 gap-3 d-flex justify-content-between">
                    <button className="btn btn-sm btn-muted" onClick={prepareModalClose}>Закрити без змін</button>

                    <ButtonWithLoading
                        className="btn btn-sm btn-success"
                        loading={loading}
                        label={'Зберегти зміни'}
                        noOriginalIconClassName={true}
                        iconClassName={'ti ti-device-floppy fs-4 me-2'}
                        onClick={onSave}
                    />
                </div>
    </SimpleModal>) : '';
}